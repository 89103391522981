import useResizeObserver from 'use-resize-observer/polyfilled';

import React, { Suspense, useState, useEffect, useRef, useCallback } from 'react';
import createStore from 'unistore';
import devtools from 'unistore/devtools';
import { Provider, useStoreState, useActions } from './hooks/unistore';
import classNames from 'classnames';
import { FaCommentDots, FaDesktop, FaEnvelope, FaFacebook, FaFileUpload, FaMicrophone } from 'react-icons/fa';

import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import i18n from './i18n';

import { actions as ACTIONS } from './store/actions';
import { initialState } from './store/initialState';
import { AudioErrorModal } from './components/AudioErrorModal/AudioErrorModal';
import { enableAnalytics, disableAnalytics, trackEvent } from './lib/analytics';

import { LegalTextModal } from './components/LegalTextModal/LegalTextModal';
import LegalDocuments from './enums/LegalDocuments';

import LocaleDropdown from './components/LocaleDropdown/LocaleDropdown';
import ExplanationBox from './components/ExplanationBox/ExplanationBox';
import LegalBanner from './components/LegalBanner/LegalBanner';
import Speaker from './components/Speaker';

import faqData from './data/faq';
import stepData from './data/steps';

import { ReactComponent as FileTranscriptionSvg } from './assets/images/file-transcription.svg';

import './custom-bulma.scss';
import 'bulma-modal-fx/dist/css/modal-fx.css';

import styles from './App.module.scss';
import MailingListForm from './components/MailingListForm/MailingListForm';

import * as tmpPIXI from 'pixi.js-legacy';

global.PIXI = tmpPIXI;
global.PIXI.utils.skipHello();
require('pixi-spine');

const store = process.env.NODE_ENV === 'production' ? createStore(initialState) : devtools(createStore(initialState));
store.subscribe(async state => {
  await i18n.changeLanguage(state.locale);
});

// Check whether cookie consent is set
if (store.getState().legal.cookieConsent) {
  enableAnalytics();
}
else {
  disableAnalytics();
}

// Trigger feature detection
store.action(ACTIONS.featureDetect)();

const AsrPhraseDemo = React.lazy(() => import('./components/AsrPhraseDemo/AsrPhraseDemo'));


const App = () => {
  const { features } = useStoreState('features');
  const { locale } = useStoreState('locale');
  const { legal } = useStoreState('legal');

  const { setLocale, setCookieConsent, setAcceptedTerms } = useActions(ACTIONS, actions => ({
    setLocale: (locale) => actions.setLocale(locale),
    setCookieConsent: (value) => actions.setCookieConsent(value),
    setAcceptedTerms: (value) => actions.setAcceptedTerms(value)
  }));

  const [hasErrored, setHasErrored] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [currentLegalDocument, setCurrentLegalDocument] = useState(LegalDocuments.None);

  const { t } = useTranslation();

  const learnMoreRef = useRef();
  const demoRef = useRef();
  const cookieBannerRef = useRef();
  const cookieBannerSize = useResizeObserver({ ref: cookieBannerRef });

  const gotoConsole = useCallback(() => {
    window.location = 'https://console.bilmalti.com'
  }, []);

  useEffect(() => {
    document.title = t('IDS_PAGE_TITLE');
  }, [locale, t])

  useEffect(() => {
    const scrollHandler = () => {
      setIsScrolled(window.scrollY > 60);
    };

    window.addEventListener('scroll', scrollHandler);
    scrollHandler();

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  return (
    <>
      {/* modals */}
      <AudioErrorModal show={hasErrored} />
      <LegalTextModal
        show={currentLegalDocument !== LegalDocuments.None}
        closeCallback={() => setCurrentLegalDocument(LegalDocuments.None)}
        document={currentLegalDocument}
      />

      {/* page */}
      <section className={`hero ${styles.hero}`}>
        <div className="hero-head">
          <nav className={classNames('navbar', 'is-fixed-top', [styles.navbar], { [styles.navbarScrolled]: isScrolled })} role="navigation" aria-label="main navigation">
            <div className={`container ${styles.flex} py-0`}>
              <div className="navbar-brand">
                <div className="navbar-item">
                  <div className={styles.logoHeader} />
                </div>
              </div>

              <div className={styles.navbarMenu}>
                <div className="navbar-item pr-0">
                  <button className="button is-primary is-rounded is-small" onClick={() => {
                    trackEvent('navbar-console', 'page');
                    gotoConsole();
                  }}>
                    <FaDesktop className="mr-2" />{t('IDS_CONSOLE')}
                  </button>
                </div>
                <div className="navbar-item">
                  <LocaleDropdown currentLocale={locale} setLocale={setLocale} />
                </div>
              </div>
            </div>
          </nav>
        </div>
        <div className={classNames('hero-body', styles.heroBody)}>
          <div className={`container ${styles.heroBodyContainer}`}>
            <div className="columns">
              <div className="column is-align-self-flex-start is-6-widescreen is-8-desktop is-8-tablet">
                <h1 className={`title is-2 ${styles.heroTextTitle}`}>
                  <Trans i18nKey="IDS_HERO_TITLE">
                    Speech to Text for the <span className={styles.primary}>Maltese</span> Language
                  </Trans>
                </h1>
                <p className={`subtitle ${styles.heroTextSubtitle} mt-4 is-4`}>
                  {t('IDS_HERO_SUBTITLE')}
                </p>
                <button className={`${styles.ctaButton} ${styles.withShadow} ${styles.tryItOut} mt-2`} onClick={() => {
                  if (learnMoreRef) {
                    trackEvent('hero-cta', 'page');
                    const navBarHeight = 52;
                    window.scrollTo(0, learnMoreRef.current.offsetTop - navBarHeight);
                  }
                }}>{t('IDS_HERO_CTA')}</button>
                {features.wasm && <button className={`${styles.ctaButton} ${styles.inverted} ${styles.withShadow} ${styles.tryItOut} mt-2 ml-4`} onClick={() => {
                  if (learnMoreRef) {
                    trackEvent('hero-cta-demo', 'page');
                    const navBarHeight = 52;
                    window.scrollTo(0, demoRef.current.offsetTop - navBarHeight);
                  }
                }}>{t('IDS_HERO_DEMO_CTA')}</button>}
              </div>
              <div className="column is-align-self-flex-end is-4-tablet is-offset-0-tablet is-3-desktop is-offset-3-widescreen is-3-widescreen">
                <div className={styles.heroine}>
                  <Speaker />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.heroMask} />
      </section>

      <section ref={learnMoreRef} className={classNames('section', styles.explanation)}>
        <div className="container">
          <div className="is-flex is-justify-content-center">
            <h2 className={classNames('title is-2 has-text-centered p-5', styles.mainSubheading)}>
              <Trans i18nKey="IDS_BANNER_TITLE">
                Get transcripts of your audio files in a <span className={styles.titleHighlight}>matter of minutes,</span> and generate <span className={styles.titleHighlight}>real-time</span> transcriptions for live events.
              </Trans>
            </h2>
          </div>

          <div className="columns my-6">
            <div className="column">
              <h3 className="subtitle is-2 has-text-primary has-text-weight-semibold">{t('IDS_EXPLAINER_TITLE_1')}</h3>
              <p className={classNames('is-size-4 mt-3', styles.textBlock)}>{t('IDS_EXPLAINER_TEXT_1')}</p>
            </div>
            <div className="column has-text-centered is-flex is-justify-content-center">
              <FileTranscriptionSvg className={styles.explainFileTranscription} />
            </div>
          </div>

          <div className={classNames('columns my-6', styles.reverseColumns)}>
            <div className="column py-6 is-flex is-justify-content-center">
              <div>
                <div className={styles.videoContainer}>
                  <video autoPlay loop muted playsInline disableRemotePlayback>
                    <source src="/live-demo.mp4" type="video/mp4" />
                  </video>
                </div>
                <p className="is-size-6 mt-5 has-text-grey has-text-grey">{t('IDS_EXPLAINER_FOOTNOTE_2')}</p>
              </div>
            </div>
            <div className="column py-6">
              <h3 className="subtitle is-2 has-text-primary has-text-weight-semibold">{t('IDS_EXPLAINER_TITLE_2')}</h3>
              <p className={classNames('is-size-4 mt-3', styles.textBlock)}>{t('IDS_EXPLAINER_TEXT_2')}</p>
            </div>
          </div>
        </div>

        <div className="container">
          <h2 className="title is-3 mb-6">{t('IDS_HOW_IT_WORKS')}</h2>
          <div className="columns">
            {stepData().map((step, index) => {
              return (
                <ExplanationBox
                  key={index}
                  stepNo={index + 1}
                  {...step}
                  className="column is-flex is-4 is-half-tablet"
                />
              )
            })}
          </div>

          <div className={classNames('has-text-centered', styles.gettingStarted)}>
            <button onClick={() => {
              trackEvent('getting-started-cta', 'page');
              gotoConsole();
            }} className={classNames(styles.ctaButton, 'has-tooltip-arrow', 'has-tooltip-multiline', 'is-size-5')} data-tooltip={t('IDS_GETTING_STARTED_CTA_TOOLTIP')}>{t('IDS_GETTING_STARTED_CTA')}</button>
            <p className={classNames('has-text-grey-darker', 'mt-2', styles.freeMinutes)}>{t('IDS_GETTING_STARTED_FREE_MINUTES')}</p>
          </div>

          <ul className={classNames('has-text-grey', styles.listItem)}>
            <li>
              <Trans i18nKey="IDS_GETTING_STARTED_NOTE_1">
                Currently access is <strong>invite only</strong>. If you’re interested in using this service, please get in touch either by <a href="mailto:info@bilmalti.com" className="link">e-mail</a> or through the <button className="button is-ghost link" onClick={() => window.Tawk_API.maximize()}>Live Chat</button> <FaCommentDots className="has-text-primary" /> below.
              </Trans>
            </li>
            <li>{t('IDS_GETTING_STARTED_NOTE_2')}</li>
          </ul>
        </div>
      </section>

      {
        features.wasm && (<section ref={demoRef} className={classNames('section', 'pt-0', styles.sectionSpacing, styles.demoSection)}>
          <div className="container">
            <div className={styles.demo}>
              <Suspense fallback={null}>
                <AsrPhraseDemo
                  acceptedTerms={legal.acceptedTerms}
                  setAcceptedTerms={setAcceptedTerms}
                  micErrorCallback={() => setHasErrored(true)}
                  showPrivacyPolicy={() => setCurrentLegalDocument(LegalDocuments.PrivacyPolicy)}
                  showTermsOfUse={() => setCurrentLegalDocument(LegalDocuments.TermsOfUse)} />
              </Suspense>
            </div>
          </div>
        </section>)
      }

      <section className={classNames('section', styles.pricingSection)}>
        <div className="container">
          <h2 className="title is-3 has-text-centered">{t('IDS_PRICING_TITLE')}</h2>
          <h2 className="subtitle mt-2 is-4 has-text-centered">{t('IDS_PRICING_SUBTITLE')}</h2>

          <h2 className={classNames('subtitle my-6 is-3 has-text-centered p-5', styles.freeCredits)}>
            <Trans i18nKey="IDS_PRICING_FREE_CREDIT">
              <span className={classNames('has-text-weight-medium', styles.shortHighlight)}>€5.00</span> free credit to get you started.
            </Trans>
          </h2>

          <div className={classNames('columns my-6', styles.pricingColumns)}>
            <div className="column is-offset-1-widescreen is-5-widescreen is-offset-2-fullhd is-4-fullhd">
              <div className={classNames('p-6', styles.pricingBox, styles.left)}>
                <p className="has-text-centered is-size-3 mb-4 has-text-primary"><FaFileUpload /></p>
                <h4 className="subtitle is-4 has-text-centered has-text-weight-medium">{t('IDS_PRICING_FILE_TRANSCRIPTION')}</h4>
                <p className={classNames('has-text-centered is-size-5 pt-4 pb-6', styles.description)}>{t('IDS_PRICING_FILE_TRANSCRIPTION_SUBTITLE')}</p>

                <ul className={styles.featureList}>
                  <li>{t('IDS_PRICING_FILE_TRANSCRIPTION_POINT_1')}</li>
                  <li>{t('IDS_PRICING_FILE_TRANSCRIPTION_POINT_2')}</li>
                  <li>{t('IDS_PRICING_FILE_TRANSCRIPTION_POINT_3')}</li>
                </ul>

                <div className="has-text-centered">
                  <div className={classNames('mt-5 px-4 py-3', styles.priceHolder)}>
                    <p className="has-text-centered is-size-4">
                      <span className="has-text-weight-medium">€0.16</span> / <span className="is-size-6">{t('IDS_PRICING_FILE_TRANSCRIPTION_UNIT')}</span>
                    </p>
                    <p className="has-text-centered is-size-7 mt-1 has-text-grey">{t('IDS_PRICING_BILLING_NOTICE')}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="column is-5-widescreen is-4-fullhd">
              <div className={classNames('p-6', styles.pricingBox, styles.right)}>

                <p className="has-text-centered is-size-3 mb-4 has-text-primary"><FaMicrophone /></p>
                <h4 className="subtitle is-4 has-text-centered has-text-weight-medium">{t('IDS_PRICING_LIVE_TRANSCRIPTION')}</h4>
                <p className={classNames('has-text-centered is-size-5 pt-4 pb-6', styles.description)}>{t('IDS_PRICING_LIVE_TRANSCRIPTION_SUBTITLE')}</p>

                <ul className={styles.featureList}>
                  <li>{t('IDS_PRICING_LIVE_TRANSCRIPTION_POINT_1')}</li>
                  <li>{t('IDS_PRICING_LIVE_TRANSCRIPTION_POINT_2')}</li>
                  <li>{t('IDS_PRICING_LIVE_TRANSCRIPTION_POINT_3')}</li>
                </ul>

                <div className="has-text-centered">
                  <div className={classNames('mt-5 px-4 py-3', styles.priceHolder)}>
                    <p className="has-text-centered is-size-4">
                      <span className="has-text-weight-medium">€0.04</span> / <span className="is-size-6">{t('IDS_PRICING_LIVE_TRANSCRIPTION_UNIT')}</span>
                    </p>
                    <p className="has-text-centered is-size-7 mt-1 has-text-grey">{t('IDS_PRICING_BILLING_NOTICE')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>

      <section className="section mt-6">
        <div className="container">
          <h2 className="title is-3">{t('IDS_FAQS_TITLE')}</h2>
          {
            faqData().map((data, index) => {
              return (
                <p key={index} className="mt-6">
                  <span className="is-block has-text-weight-medium is-size-4">{t(data.questionIds)}</span>
                  <span className="is-size-4">{t(data.answerIds)}</span>
                </p>
              )
            })
          }
        </div>
      </section>

      <section className={`section mt-6 ${styles.signUp}`}>
        <div className="container has-text-centered mt-6 mb-6">
          <div className="columns is-centered">
            <div className="column is-8-tablet is-6-desktop">
              <h2 className="title is-3">{t('IDS_MAILING_LIST_TITLE')}</h2>
              <h4 className="is-size-4">{t('IDS_MAILING_LIST_SUBTITLE')}</h4>
            </div>
          </div>

          <div className="columns is-centered mt-4">
            <div className="column is-relative is-8-tablet is-5-desktop is-12-mobile">
              <MailingListForm />
            </div>
          </div>
        </div>
      </section>

      <footer className={`${styles.footer} footer has-text-white p-5`} style={{ marginBottom: `${cookieBannerSize.height || 0}px` }}>
        <div className="container mt-4 mb-4">
          <div className={classNames('columns', styles.footerLayout)}>
            <div className={classNames('column has-text-centered-mobile', styles.footerLogo)}>
              <div className={classNames(styles.logoFooterImage)} />
            </div>

            <div className={classNames('column has-text-centered', styles.footerLegal)}>
              <p className={styles.policies}>
                <button className="button is-ghost has-text-white" onClick={() => setCurrentLegalDocument(LegalDocuments.CookiePolicy)}><small>{t('IDS_FOOTER_COOKIE_POLICY')}</small></button>&nbsp;|&nbsp;
                <button className="button is-ghost has-text-white" onClick={() => setCurrentLegalDocument(LegalDocuments.PrivacyPolicy)}><small>{t('IDS_FOOTER_PRIVACY_POLICY')}</small></button>&nbsp;|&nbsp;
                <button className="button is-ghost has-text-white" onClick={() => setCurrentLegalDocument(LegalDocuments.TermsOfUse)}><small>{t('IDS_FOOTER_TERMS_OF_USE')}</small></button>
              </p>
              <p className={styles.copyrightNotice}>
                <small>{t('IDS_FOOTER_COPYRIGHT_NOTICE_PART_1')}</small><br />
                <small>{t('IDS_FOOTER_COPYRIGHT_NOTICE_PART_2')}</small>
              </p>
            </div>

            <div className={classNames('column has-text-right has-text-centered-mobile', styles.footerContact)}>
              <a href="mailto:info@bilmalti.com" className="has-tooltip-active">
                <FaEnvelope size={24} />
              </a>
              <a href="https://www.facebook.com/bilmalti.mt">
                <FaFacebook size={24} className="ml-4" />
              </a>
            </div>
          </div>
        </div>
      </footer>

      <LegalBanner ref={cookieBannerRef}
        cookieConsent={legal.cookieConsent}
        setCookieConsent={setCookieConsent}
        acceptedTerms={legal.acceptedTerms}
        setAcceptedTerms={setAcceptedTerms}
        showPrivacyPolicy={() => setCurrentLegalDocument(LegalDocuments.PrivacyPolicy)}
        showTermsOfUse={() => setCurrentLegalDocument(LegalDocuments.TermsOfUse)}
        showCookiePolicy={() => setCurrentLegalDocument(LegalDocuments.CookiePolicy)} />
    </>
  )
}

function getApp() {
  return (
    <Provider value={store}>
      <App />
    </Provider>
  )
}

export default getApp;
